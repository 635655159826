<template>
    <v-app>
        <div class="flex-row-fluid d-flex flex-column position-relative p-14 overflow-hidden">
            <div class="d-flex flex-column-fluid flex-center">
                <div class="d-flex flex-column ">
                    <template>

                            <h1 class="mb-5">Your payment was not processed</h1>
                            <div class="col-md-12 text-center">
                                <img src="media/payments/error.png" style="width: 95px; text-align: center"/>
                            </div>
                        <v-col cols="12" class="mt-10">
                                <span class="font-size-lg text-danger">Sorry your payment was not processed. Please check with your payment service provider. You can use following payment methods
                                to pay.</span>
                        </v-col>

                            <v-col cols="12">
                                <h5 class="mb-2 mt-10 ">You total amount due for today is</h5>
                                <h2>Rs.{{ admission_fee }}.00</h2>
                            </v-col>
                        <v-col cols="12" class="mt-10">
                            <h4>How would you like to pay for your application?</h4>
                        </v-col>

                        <v-col cols="12" class="text-left">
                                <a v-if="admission_fee > 0"  @click.prevent="payAtCollege()" class="payment-img"><img src="/media/payments/cash.jpg" /></a>
                                <a v-if="payment_status && admission_fee > 0"  @click.prevent="payWithEsewa()"  class="payment-img"><img src="/media/payments/esewa_logo.jpg" /></a>
                            </v-col>
                        <v-col>
                            <h5 class="mt-10">Note</h5>
                            <ul>
                                <li> For cash/offline payment, you can pay the due amount at our college premise. Your admit card will be issued after the payment is received. </li>
                                <li> For online payment, you can pay using Esewa account. Your admit card will be sent to your email after successful payment. </li>
                            </ul>
                        </v-col>
                            <div class="col-md-12 text-left">
                                <a @click="goToHome"><i style=" font-size: 22px;" class="fas fa-home"></i>&nbsp; Home</a>
                            </div>
                    </template>
                </div>
            </div>
        </div>
        <vue-confirm-dialog></vue-confirm-dialog>
    </v-app>
</template>

<script>
import EssentialService from "@/core/services/enrollment/EssentialService";
import {ADMISSION_URL, API_URL} from "@/core/config";
const essentialService = new EssentialService();
export default {
  name: "PaymentError",
    data() {
    return {
      payment: true,
      overlay: false,
      app_env: process.env.VUE_APP_ENV,
      admission_fee:0,
      product_code:null,
      payment_gateway:null,
      payment_status:0,
      esewa_stage_active:0
    }
  },
  mounted() {
      if (localStorage.hasOwnProperty('_newToken')) {
          this.payment = true;
          this.getAdmissionFee();
          this.getUniqueProductCode();
          this.getPaymentDetailInformation();
      }else{
          this.payment = false
          this.not_valid = true;
          this.$snotify.warning("You will be redirected to main page in 5 seconds")
          setTimeout(() => {
              this.$router.push({name: 'index'})
          }, 500000);
      }
  },
    methods:{
        getPaymentDetailInformation(){
            if (localStorage.hasOwnProperty('_newToken')) {
                essentialService
                    .getPaymentDetailInformation('esewa')
                    .then(response => {
                        this.payment_gateway=response.data.setting;
                        this.payment_status=response.data.payment_status;
                    })
                    .catch(error => {

                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
        getAdmissionFee(){
            if (localStorage.hasOwnProperty('_newToken')) {
                let id=localStorage.getItem('_newToken');
                essentialService
                    .getAdmissionFee(id)
                    .then(response => {
                        this.admission_fee=response.data.admission_fee;
                    })
                    .catch(error => {
                        this.$snotify.error("Something went wrong. Please try again later");
                    })
            }
        },
        getUniqueProductCode(){
            if (localStorage.hasOwnProperty('_newToken')) {
                let id=localStorage.getItem('_newToken');
                essentialService
                    .getUniqueProductCode(id)
                    .then(response => {
                        this.product_code=response.data.product_code;
                    })
                    .catch(error => {
                        this.$snotify.error("Something went wrong. Please try again later");
                    })
            }
        },
        goToHome() {
            this.$router.push({name: 'index'})
        },
        newPreRegistration() {
              this.$store.commit("setEnrollment", {})
              this.$router.push({name: 'pre-registration'});
        },
        payWithEsewa(){

            if (localStorage.hasOwnProperty('_newToken')) {
                if(this.payment_status){
                    let id=localStorage.getItem('_newToken');
                    let verification_success_url =''
                    let verification_fail_url = ''
                    let path = 'https://uat.esewa.com.np/epay/main';
                    if(this.esewa_stage_active){
                        verification_success_url = API_URL + `api/admission/enrollment/${id}/esewa-verification?q=su`;
                        verification_fail_url = ADMISSION_URL + `#/error`;
                    }
                    else{
                        verification_success_url = API_URL + `api/admission/enrollment/${id}/esewa-verification?q=su`;
                        verification_fail_url = ADMISSION_URL + `#/error`;
                        path = process.env.VUE_APP_ESEWA_PAYMENT_URL;
                    }
                    let productCode=null;
                    if(this.product_code){
                        productCode=this.product_code;
                    }else{
                        productCode ='ONLINE_ADMISSION_FEE'+'_'+id;
                    }
                    let merchant_id=null;
                    if(this.payment_gateway && this.payment_gateway.merchant_id){
                        merchant_id=this.payment_gateway.merchant_id;
                    }else{
                        merchant_id=process.env.VUE_APP_ESEWA_MERCHANT;
                    }
                    var params = {
                        amt: this.admission_fee,
                        psc: 0,
                        pdc: 0,
                        txAmt: 0,
                        tAmt: this.admission_fee,
                        pid: productCode,
                        scd: merchant_id,
                        su:  verification_success_url,
                        fu: verification_fail_url
                    }

                    this.esewaPost(path, params)
                }

            }
        },
        payAtCollege() {
            if (localStorage.hasOwnProperty('_newToken')) {
                let id=localStorage.getItem('_newToken');

                this.$confirm({
                    message: `You have selected to pay at college. Your admit card will be issued once a payment is received. Please make sure to make payment to secure your entrance date and time.`,
                    button: {
                        no: "Cancel",
                        yes: "Submit application"
                    },

                    callback: confirm => {
                        if (confirm) {
                            this.overlay = true
                            let data = {amt: this.admission_fee};
                            essentialService
                                .payAtCollege(id,data)
                                .then(response => {
                                    this.$snotify.success("Thank you for submitting your application")
                                    this.$router.push({name: 'success'});
                                    if (localStorage.hasOwnProperty('_newToken')) {
                                        localStorage.removeItem('_newToken');
                                    }
                                    this.overlay = false
                                    this.payment = false

                                }).catch(error => {
                                this.overlay = false
                                this.$snotify.error("Something went wrong. Please try again later");
                            })
                        }
                    }
                });
            }else{
                this.$snotify.error("Something went wrong.");
            }
        },
        esewaPost(path, params) {
            var form = document.createElement("form");
            form.setAttribute("method", "POST");
            form.setAttribute("action", path);

            for(var key in params) {
                var hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
            }
            document.body.appendChild(form);
            form.submit();
        },
        getByAppUrl(){
            option
                .getByKey('ESEWA_STAGE_ACTIVE')
                .then(response => {
                    if(response.data){
                        this.esewa_stage_active = response.data.value;
                    }


                }).catch((err) => {
            }).finally(() => {


            });
        },
  }

}
</script>

<style scoped>
p {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size:20px;
    margin: 0;
}
h2 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 700;
    font-size: 30px;

}
i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
}
</style>
