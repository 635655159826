var domain = (function(){
    var i=0,domain=document.domain,p=domain.split('.'),s='_gd'+(new Date()).getTime();
    while(i<(p.length-1) && document.cookie.indexOf(s+'='+s)==-1){
        domain = p.slice(-1-(++i)).join('.');
    }
    return domain;
})();
var maindomain = domain.replace(/^[^.]+\./g, "");

var admissionPortalUrl = process.env.VUE_APP_PROTOCOL+'admission.'+maindomain+process.env.VUE_APP_PORT+'/';
maindomain = process.env.VUE_APP_PROTOCOL+maindomain+'/';

export const API_URL = maindomain;
export const ADMISSION_URL = admissionPortalUrl;
export default {API_URL, ADMISSION_URL};

